import { DEFAULT_LOCALE, getCurrentLocale } from '../utils/translation'

export async function addChat(productId, orderId = null) {
  return fetch(`/chats`, {
    method: 'POST',
    body: JSON.stringify({ productId, orderId }),
  })
    .then((response) => response.json())
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}

export async function postMessage(chatId, message) {
  return fetch(`/chats/${chatId}/messages`, {
    method: 'POST',
    body: JSON.stringify({
      content: message,
    }),
  })
    .then((response) => response.json())
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}

export async function postPictures(chatId, pictures) {
  return fetch(`/chats/${chatId}/pictures`, {
    method: 'POST',
    body: JSON.stringify(pictures),
  })
    .then((response) => response.json())
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}

export async function getAll(page) {
  const locale = getCurrentLocale()
  const localUriPrefix = locale === DEFAULT_LOCALE ? '' : `/${locale}`

  const URI = page ? `${localUriPrefix}/chats?page=${page}` : `${localUriPrefix}/chats`

  return fetch(URI, { method: 'GET' })
    .then((response) => response.json())
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}

export async function get(id) {
  return fetch(`/chats/${id}`, { method: 'GET' })
    .then((response) => response.json())
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}

/**
 * This method is used  to mark messages as read
 * if the messageIds is set : only the message with the followingIds will be read
 *
 * @param {number}chatId
 * @param {array|null|undefined} messageIds
 *
 * @return {Promise<Response>}
 */
export async function readChatMessages(chatId, messageIds) {
  const init =
    !messageIds || messageIds.length === 0 ? { method: 'PUT' } : { method: 'PUT', body: JSON.stringify({ messageIds }) }

  return fetch(`/chats/${chatId}/read`, init).catch((error) => {
    // eslint-disable-next-line no-console
    console.error(error)
  })
}
