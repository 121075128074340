import { isMobile } from '../../utils/responsive'
import { hideChatWidget } from '../../plugins/chat-widget'

function toggleFilterDropdownIcon(element) {
  const icon = element.querySelector('[data-toggle-icon]')
  if (!icon) return

  if (element.classList.contains('open')) {
    icon.classList.remove('icon--simple-plus')
    icon.classList.add('icon--simple-minus')
  } else {
    icon.classList.remove('icon--simple-minus')
    icon.classList.add('icon--simple-plus')
  }
}

/**
 * Used to counter an ugly glitch when filters or sort modals passes under those elements
 */
export function hideZIndexes() {
  const header = document.querySelector('#main-header')
  if (header) header.style.zIndex = -1

  const reinsurance = document.querySelector('#reinsurance-section')
  if (reinsurance) reinsurance.style.zIndex = -1

  const footer = document.querySelector('.main-footer')
  if (footer) footer.style.zIndex = -1
}

/**
 * Used to counter an ugly glitch when filters or sort modals passes under those elements
 */
export function showZIndexes() {
  const header = document.querySelector('#main-header')
  if (header) header.style.zIndex = 32

  const reinsurance = document.querySelector('#reinsurance-section')
  if (reinsurance) reinsurance.style.zIndex = 1

  const footer = document.querySelector('.main-footer')
  if (footer) footer.style.zIndex = 1
}

/**
 * This function open all the filter-dropdown when we switch from mobile to desktop
 *
 */
function handleResize() {
  if (!isMobile()) {
    window.removeEventListener('resize', handleResize)
    document.querySelector('.layout__filter .form')?.classList.remove('open')
    document.documentElement.classList.remove('menu--open')
    showZIndexes()
    document.querySelectorAll('.layout__filter .form .filter-dropdown').forEach((dropdown) => {
      dropdown.classList.add('open')
      toggleFilterDropdownIcon(dropdown)
    })
  }
}

function handleFilterHandleClick(filterForm) {
  if (window.innerWidth > 767) return

  document.documentElement.classList.toggle('menu--open')
  filterForm.classList.toggle('open')

  if (document.documentElement.classList.contains('menu--open')) {
    hideChatWidget()
    hideZIndexes()
  } else {
    showZIndexes()
  }

  filterForm.querySelectorAll('.filter-dropdown').forEach((filterDropdown) => {
    filterDropdown.classList.remove('open')
    toggleFilterDropdownIcon(filterDropdown)
  })

  window.removeEventListener('resize', handleResize)
  window.addEventListener('resize', handleResize)
}

function handleScrollToTop() {
  const scrollToTopButton = document.getElementById('scroll-to-top-cta')
  if (!scrollToTopButton) return

  scrollToTopButton.addEventListener('click', () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })
}

/**
 * Add interactions to the filters column to act as a pop-in
 */
window.addEventListener('DOMContentLoaded', () => {
  // filter menu VM
  const filterContainer = document.querySelector('.layout__filter')
  const closeButtons = filterContainer?.querySelectorAll('.filter-infos button')

  if (!filterContainer) return

  const filterHandles = filterContainer.querySelectorAll('[data-handle-menu]')
  const filterForm = filterContainer.querySelector('.form')

  closeButtons.forEach((button) => {
    button.addEventListener('click', () => {
      document.documentElement.classList.remove('menu--open')
      filterForm.classList.remove('open')
      showZIndexes()
    })
  })

  filterHandles.forEach((filterHandle) => {
    filterHandle.addEventListener('click', () => handleFilterHandleClick(filterForm))
  })

  handleScrollToTop()
})
