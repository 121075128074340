window.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('click', (event) => {
    const triggers = document.querySelectorAll('[data-aside-modal-id="aside-modal-user-reviews"]')
    if (!triggers) return

    const clickedOnTrigger = Array.prototype.some.call(triggers, (trigger) => trigger.contains(event.target))
    if (!clickedOnTrigger) return

    triggers.forEach((trigger) => {
      window.showElementsAsync('react-modals-container', 'user-reviews-modal', 'Modals/UserReviews/UserReviewsModal', {
        pseudo: trigger.dataset.pseudo,
      })
    })
  })
})
