import Swiper, { FreeMode, Mousewheel } from 'swiper'
import React from 'react'
import {
  getBikeWarrantyModalConfig,
  getFreeReturnModalConfig,
  getWarrantyModalConfig,
} from '../../../screens/product/modals/config'

const handlePurchaseProtectionSwiper = () => {
  // eslint-disable-next-line no-new
  new Swiper('#purchase-protection-swiper', {
    modules: [FreeMode, Mousewheel, Mousewheel],
    slidesPerView: 'auto',
    spaceBetween: 12,
    freeMode: {
      enabled: true,
      momentumRatio: 0.8,
    },
    mousewheel: {
      enabled: true,
      forceToAxis: true,
    },
    edgeSwipeDetection: 'prevent',
    breakpoints: {
      1024: {
        spaceBetween: 0,
      },
    },
  })
}

const handleFreeReturnModal = () => {
  const triggers = document.querySelectorAll('[purchase-protection-card="free-return-card"]')
  triggers?.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      const isPro = trigger.dataset.isPro === 'true'

      window.showElementsAsync(
        'react-modals-container',
        'free-return-modal',
        'Modals/common/CommonModal',
        getFreeReturnModalConfig(isPro)
      )
    })
  })
}

const handleCommercialWarrantyModal = () => {
  const triggers = document.querySelectorAll('[purchase-protection-card="commercial-warranty-card"]')
  triggers?.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      const isPro = trigger.dataset.isPro === 'true'
      const { isBike } = trigger.dataset

      window.showElementsAsync(
        'react-modals-container',
        'commercial-warranty-modal',
        'Modals/common/CommonModal',
        isBike && isBike !== 'false' ? getBikeWarrantyModalConfig(isPro) : getWarrantyModalConfig(isPro)
      )
    })
  })
}

const handleMultiplePaymentModal = () => {
  const triggers = document.querySelectorAll('[purchase-protection-card="multiple-payment-card"]')
  triggers?.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      window.showElementsAsync('react-modals-container', 'multiple-payment-modal', 'Modals/common/CommonModal', {
        html: `
        Sur Campsider, vous pouvez payer <strong>en 3x, 4x ou 10x par carte bancaire</strong>. C'est une solution
        simple et flexible qui vous permet d'étaler le paiement de votre achat sur plusieurs mois. Vous profitez ainsi de
        votre produit immédiatement, sans impacter votre budget mensuel.
        <br />
        <br />
        Comment ça marche ? Choisissez l'option de paiement en plusieurs fois lors de votre commande, avec notre
        partenaire Alma. Sélectionnez le nombre de mensualités et validez votre achat.
      `,
        title: 'Paiement en 3x, 4x ou 10x',
        titleRightItem: <img src="/build/images/logos/alma.png" alt="Alma logo" style={{ height: '15px' }} />,
      })
    })
  })
}

const handleCustomerServiceModal = () => {
  const triggers = document.querySelectorAll('[purchase-protection-card="customer-service-card"]')
  triggers?.forEach((trigger) => {
    trigger?.addEventListener('click', () => {
      window.showElementsAsync('react-modals-container', 'customer-service-modal', 'Modals/common/CommonModal', {
        html: `
        Chez Campsider, nous sommes convaincus qu'une expérience client réussie passe par un service client irréprochable.
        C'est pourquoi nous mettons tout en œuvre pour vous offrir une assistance réactive et personnalisée, que vous ayez
        besoin d'aide pour passer commande, de conseils sur nos produits ou simplement d'une information complémentaire.
        <br />
        <br />
        Deux options simples pour nous contacter :
        <ul>
            <li>
              <strong>Formulaire de contact</strong> : accessible directement
              <a class="is-underlined" href="https://support.campsider.com/hc/fr/requests/new" target="_blank">en cliquant sur ce lien<a/>,
               il vous permet de nous envoyer votre message en quelques clics.
            </li>
            <li>
              <strong>Email</strong> : écrivez-nous à
              <a class="is-underlined" href="mailto:hello@campsider.com">hello@campsider.com</a> et nous vous répondrons dans les meilleurs délais.
            </li>
        </ul>
        <br />
        Notre équipe est à votre disposition du <strong>lundi au samedi de 10h à 19h</strong>.
      `,
        title: 'Service client réactif',
      })
    })
  })
}

export function handlePurchaseProtection(timeout = 0) {
  handlePurchaseProtectionSwiper()
  setTimeout(() => {
    handleFreeReturnModal()
    handleCommercialWarrantyModal()
    handleMultiplePaymentModal()
    handleCustomerServiceModal()
  }, timeout)
}

window.addEventListener('DOMContentLoaded', () => {
  handlePurchaseProtection(1500)
})
