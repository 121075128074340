export function handleMakeOfferTriggers() {
  const makeOfferButtons = document.querySelectorAll('.js_smo')

  makeOfferButtons.forEach((btn) => {
    btn.addEventListener('click', async () => {
      const { userId, redirection, productJson = '' } = btn.dataset

      if (!userId) {
        window.location.replace(redirection)
        return
      }

      window.showElementsAsync('react-modals-container', 'make-offer-modal', 'Modals/MakeOffer/MakeOfferModal', {
        redirection,
        productJson,
      })
    })
  })
}

window.addEventListener('DOMContentLoaded', () => {
  handleMakeOfferTriggers()
})
