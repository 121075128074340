import React from 'react'

export const handleCalendlyTriggers = () => {
  const triggers = document.querySelectorAll('[data-calendly-trigger]')

  triggers.forEach((trigger) => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault()

      window.showElementsAsync('react-modals-container', 'calendly-modal-cpt', 'Modals/common/CommonModal', {
        contentFullHeight: true,
        title: 'Prendre un rendez-vous',
        children: (
          <iframe
            src="https://calendly.com/d/zzr-7gw-qk8/conseil-expert-cycle?hide_gdpr_banner=1&primary_color=be4a09"
            width="100%"
            height="100%"
          />
        ),
      })
    })
  })
}

window.addEventListener('DOMContentLoaded', () => {
  handleCalendlyTriggers()
})
