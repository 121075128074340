export function me() {
  return fetch(`/me`, {
    method: 'GET',
  })
    .then((response) => response.json())
    .catch((error) => error.json())
}

export function getHeaders() {
  return me().then((user) =>
    user.token
      ? {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        }
      : {
          Accept: '*/*',
          'Content-Type': 'application/json',
        }
  )
}
