import intlTelInput from 'intl-tel-input'
import { debounce } from 'lodash'
import { toLowerCaseWithoutAccent } from './string'
import { isMobile } from './responsive'
import { showZIndexes } from '../block/products/layout'

const FAVORITE_COUNTRY_PHONE = ['fr', 'be', 'es', 'it', 'nl', 'ch']
const TRANSLATION_COUNTRY_NAME_REGEX = /.* \((.*)\)/g

let intlTelInputGlobalsUpdated = false

/**
 * Use the untranslated / original country name (between parentheses) rather than the english name
 * this action will be done once
 *
 * @see https://github.com/jackocnr/intl-tel-input#static-methods
 */
const updateIntlTelInputGlobals = () => {
  if (intlTelInputGlobalsUpdated) return

  if (window.intlTelInputGlobals) {
    window.intlTelInputGlobals.getCountryData().forEach((countryData) => {
      countryData.name = TRANSLATION_COUNTRY_NAME_REGEX.test(countryData.name)
        ? countryData.name.replace(TRANSLATION_COUNTRY_NAME_REGEX, '$1')
        : countryData.name
    })
    intlTelInputGlobalsUpdated = true
  }
}

/**
 * Initialize an international phone input.
 *
 * If this input got an data-international-phone-id attribute,
 * the element with this id will be set with the internationalPhone
 *
 *
 * @param {HTMLInputElement} phoneInput
 * @param {?HTMLDivElement|?HTMLSpanElement} dropdownContainer
 * @return {*}
 */

export const initInternationalPhoneInput = (phoneInput, dropdownContainer = null) => {
  updateIntlTelInputGlobals()

  // Init the modal phone (
  const iti = intlTelInput(phoneInput, {
    initialCountry: 'fr',
    utilsScript: '/build/vendors/intl-tel-input/utils.js',
    separateDialCode: true,
    autoPlaceholder: 'aggressive',
    preferredCountries: FAVORITE_COUNTRY_PHONE,
    ...(dropdownContainer ? { dropdownContainer } : {}),
  })
  const internationalPhoneInput = phoneInput.dataset.internationalPhoneId
    ? document.getElementById(phoneInput.dataset.internationalPhoneId)
    : null

  const handleItiChange = () => {
    iti.setNumber(phoneInput.value) // Only way to format a valid number
    if (internationalPhoneInput) internationalPhoneInput.value = iti.getNumber() // set the international phone value

    if (phoneInput.value && !iti.isValidNumber()) {
      phoneInput.setCustomValidity('Ce téléphone est invalide')
    } else {
      phoneInput.setCustomValidity('')
    }
  }
  // Formats phoneInput and indicates if it is invalid (or not)
  phoneInput.addEventListener('input', handleItiChange)
  phoneInput.addEventListener('countrychange', handleItiChange)

  phoneInput.addEventListener('open:countrydropdown', (e) => {
    const { target } = e
    target.classList.add('is-open')
  })
  phoneInput.addEventListener('close:countrydropdown', (e) => {
    const { target } = e
    target.classList.remove('is-open')
  })

  // Prevent To close a dropdown that could contain our dropdownContainer
  // (the clicked component can be removed from the DOM by the intlTelInput and close this dropdown)
  if (dropdownContainer) {
    dropdownContainer.addEventListener('click', (e) => {
      e.stopPropagation()
      e.stopImmediatePropagation()
    })
  }

  return iti
}

/**
 * Create a new Error Paragraph
 *
 * @param {HTMLInputElement, HTMLSelectElement, HTMLTextAreaElement} input
 *
 * @returns {HTMLElement}
 */
export const ErrorParagraph = (input) => {
  const { emptyError } = input.dataset

  const parser = new DOMParser()
  const error = input.value ? 'Cette valeur est invalide' : emptyError || 'Cette valeur ne doit pas être vide.'

  const template = `
    <p class="has-text-danger has-margin-bottom-1">
        ${error}
    </p>
  `
  return parser.parseFromString(template, 'text/html').body.firstElementChild
}

/**
 * Validate the textarea content when the user press the Enter key without shift
 * or when the value change (eg : when the dropdown is closed)
 *
 * @param {KeyboardEvent|Event} e - The keyboard event
 */
const handleEnter = (e) => {
  if ((e.type === 'keydown' && e.key === 'Enter' && !e.shiftKey) || e.type === 'change') {
    e.stopPropagation()
    e.stopImmediatePropagation()
    e.preventDefault()

    const dataDropdown = e.target.closest('[data-dropdown]')
    const dataHandle = dataDropdown.querySelector('[data-handle]')
    const dropdown = dataDropdown.querySelector('.dropdown')

    /**
     * Close the dropdown except in the following cases :
     * - Mobile screen (the validation require to validate with a button)
     * - date input (change is trigger on every change of months, days or years)
     */
    if (dataDropdown && !isMobile() && !(e.currentTarget.type === 'date' && e.type === 'change')) {
      dataDropdown.classList.remove('active')
    }

    if (!e.currentTarget.value.trim()) {
      dataHandle.value = dropdown.dataset.empty
      dataHandle.classList.remove('has-text-grey-600')
      return
    }

    if (e.target.type === 'date' && e.target.value.match(/\d{4}-\d{2}-\d{2}/)) {
      const parsedDate = e.target.value.match(/(\d{4})-(\d{2})-(\d{2})/)
      dataHandle.value = `${parsedDate[3]}/${parsedDate[2]}/${parsedDate[1]}`
    } else if (e.target.classList.contains('phone')) {
      const iti = window.intlTelInputGlobals ? window.intlTelInputGlobals.getInstance(e.target) : null

      dataHandle.value = iti
        ? iti.getNumber(window?.intlTelInputUtils?.numberFormat?.INTERNATIONAL)
        : (dataHandle.value = e.currentTarget.value.trim().replace(/[\n\r]/gm, ' '))
    } else {
      dataHandle.value = e.currentTarget.value.trim().replace(/[\n\r]/gm, ' ')
    }
    dataHandle.classList.add('has-text-grey-600')
  }
}

/**
 * Validate the click on a checkbox :
 * - Close the dropdown
 * - Update the dataHandle value
 *
 * @todo : handle key event too
 *
 * @param {MouseEvent} e - The clickEvent event
 */
const handleCheckboxesClick = (e) => {
  const dataDropdown = e.target.closest('[data-dropdown]')
  const dataHandle = dataDropdown.querySelector('[data-handle]')
  const checkbox = e.target.closest('.checkbox')
  const checkboxes = e.currentTarget.closest('.checkboxes').querySelectorAll('input:checked').length

  if (checkbox) {
    const checkboxLabel = checkbox.querySelector('label')
    const checkBoxInput = checkbox.querySelector('input')

    // If we have a checkboxe s that can have multiple values
    if (checkBoxInput.hasAttribute('name') && checkBoxInput.getAttribute('name').slice(-2) === '[]') {
      dataHandle.value = checkboxes > 1 ? `${checkboxes} options` : `1 option`
      dataHandle.classList.add('has-text-grey-600')

      return // prevent to close the dropdown
    }
    // If we have a checkboxes that can have only one value

    dataHandle.value = checkboxLabel.innerText
    dataHandle.classList.add('has-text-grey-600')
  }
  // TODO hide the content with a content : act as an anti-pattern
  // else {
  //   dataHandle.value = dataHandle.dataset.empty
  //   dataHandle.classList.remove('has-text-grey-600')
  // }

  if (!isMobile()) dataDropdown.classList.remove('active')
}

/**
 * Validate the click on a checkbox :
 * - Close the dropdown
 * - Update the dataHandle value
 * - Update the real select value
 *
 * @todo : use hidden input rather than the real select to generate less html
 * @todo : handle key event too
 *
 * @param {MouseEvent} e - The clickEvent event
 */
const handleSelectsClick = (e) => {
  if (e.target.closest('.is-ignored')) return

  const dataDropdown = e.target.closest('[data-dropdown]')
  const dataHandle = dataDropdown.querySelector('[data-handle]')
  const realSelect = e.currentTarget.querySelector('select')
  const currentItem = e.target.closest('li')
  const previousSelectedItem = e.currentTarget.querySelector('li.is-selected') // No multiple required yet

  // On desktop : we close the pop-in on click. Not on mobile device
  if (!isMobile()) dataDropdown.classList.remove('active')

  // Except with an item inside a category-selector :/
  if (isMobile() && currentItem && currentItem.closest('.is-category-selector')) {
    dataDropdown.classList.remove('active')
    e.currentTarget.dispatchEvent(new CustomEvent('categoryselected', { bubbles: true }))
  }

  if (previousSelectedItem) previousSelectedItem.classList.remove('is-selected')

  if (currentItem) {
    currentItem.classList.add('is-selected')
    dataHandle.value = currentItem.innerText.trim()
    dataHandle.classList.add('has-text-grey-600')
    realSelect.value = currentItem.dataset.value
    realSelect.dispatchEvent(new Event('change'))
  } else {
    dataHandle.value = dataHandle.dataset.empty
    dataHandle.classList.remove('has-text-grey-600')
    realSelect.value = ''
    realSelect.dispatchEvent(new Event('change'))
  }
}

/**
 * This function is used by a search__input
 * (added with selects-v2 fields with searchable option set to true)
 * It filters the targeted ul (obtains from the search__input dataset)
 *
 * @param {HTMLInputElement} element - The search input
 */
const handleSearch = (element) => {
  const {
    dataset: { target },
    value,
  } = element
  let visibleItem = 0

  if (!element) return

  const searchableTarget = document.getElementById(target)

  // Filtering
  if (searchableTarget) {
    const valueToSearch = toLowerCaseWithoutAccent(value)

    // The filtering will be done with un-rendered cloned list item to prevent lag due to dom update
    const clonedListItems = Array.from(searchableTarget.querySelectorAll('li')).map((li) => li.cloneNode(true))

    searchableTarget.innerHTML = clonedListItems
      .map((item) => {
        if (item.classList.contains('is-ignored')) return item

        // Show or hide the empty message (the last list liste item)
        if (item.classList.contains('empty-message')) {
          if (visibleItem === 0) {
            item.classList.remove('is-hidden')
          } else {
            item.classList.add('is-hidden')
          }
          return item
        }

        if (!toLowerCaseWithoutAccent(item.innerText).includes(valueToSearch)) {
          item.classList.add('is-hidden')
        } else {
          item.classList.remove('is-hidden')
          visibleItem += 1
        }

        return item
      })
      .reduce((acc, item) => acc + item.outerHTML, '')
  }
}

/**
 * Validate the click on the mobile validation button :
 *
 * @param {MouseEvent} e - The clickEvent event
 */
const handleValidation = (e) => {
  e.stopPropagation()
  e.stopImmediatePropagation()
  e.preventDefault()

  const dataDropdown = e.target.closest('[data-dropdown]')

  dataDropdown.classList.remove('active')
  document.documentElement.classList.remove('menu--open')
  showZIndexes()
}

/**
 * Initialize the .is-campsider-v2 row inside an HTMLElement
 *
 * @param {HTMLElement|Document} container
 */
export const initCampsiderV2Row = (container) => {
  container.querySelectorAll('.is-campsider-v2 .textarea, .is-campsider-v2 .input').forEach((element) => {
    element.addEventListener('keydown', handleEnter)
    element.addEventListener('change', handleEnter)
  })

  container
    .querySelectorAll('.is-campsider-v2 .checkboxes')
    .forEach((element) => element.addEventListener('click', handleCheckboxesClick))

  container
    .querySelectorAll('.is-campsider-v2 .select-v2')
    .forEach((element) => element.addEventListener('click', handleSelectsClick))

  container.querySelectorAll('.is-campsider-v2 .search__input').forEach((element) =>
    element.addEventListener(
      'input',
      debounce(() => handleSearch(element), 250)
    )
  )

  container
    .querySelectorAll('.is-campsider-v2 .button--primary')
    .forEach((button) => button.addEventListener('click', handleValidation))

  container.querySelectorAll('.is-campsider-v2 .phone').forEach((element) => {
    const dropdownCountries = element.parentNode.querySelector('.dropdown-countries')
    initInternationalPhoneInput(element, dropdownCountries)
  })
}

window.addEventListener('DOMContentLoaded', () => {
  // Add specific class to the selects with the is-campsider class  when they have no value.
  const updateSelectPlaceholderClass = (element) => {
    if (element.value) {
      element.classList.remove('has-text-grey-600')
    } else {
      element.classList.add('has-text-grey-600')
    }
  }

  document.querySelectorAll('.is-campsider  select').forEach((element) => {
    updateSelectPlaceholderClass(element)
    element.addEventListener('change', () => updateSelectPlaceholderClass(element))
  })

  // Init all the is-campsider-v2 row in our document.
  initCampsiderV2Row(document)

  // Authorize only number in Input with the js-form-num class
  const inputsNum = document.querySelectorAll('.js-form-num input[type=text]')

  if (inputsNum && inputsNum.length > 0)
    inputsNum.forEach((inputNum) => {
      inputNum.addEventListener('keydown', (e) => {
        if (/^[A-zÀ-ȕ-.!"'#%&,:;<>=@{}~€$£()*+/?|¨µ\s]$/.test(e.key)) e.preventDefault()
      })
      inputNum.addEventListener('input', (e) => {
        if (e.currentTarget.value === '0') e.currentTarget.value = '1'
      })
    })
})
